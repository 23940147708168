<template>
  <div class="raffle-sidebar">
    <div class="comments-panel">
      <h4>{{ $t('desktop.chat.commentsTitle') }}</h4>
      <div class="disclaimer">{{ $t('desktop.chat.commentsCall') }}</div>
      <ul>
        <div ref="commentsList" class="comments-container">
          <li class="dynamic-comment" v-for="comment in comments" :key="comment.id">
            <comment-bubble :comment="comment" />
          </li>
        </div>
        <li class="new-comment">
          <div class="bubble own">
            <img :src="profileImage" />
            <div v-if="isBanned" class="alert alert-danger" role="alert">
              {{ $t('desktop.raffle.chatBanned') }}
            </div>
            <div v-if="!isBanned" class="bubble-text">
              <form @submit.prevent="sendComment">
                <textarea v-model="commentText" :placeholder="$t('desktop.chat.insideComment')"></textarea>
                <button type='submit'><i class="fa fa-comment"></i> {{ $t('desktop.chat.comment') }}</button>
              </form>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>

import * as humps from 'humps';

import { INITIALIZE, FETCH_COMMENTS, COMMENT_RECEIVED, REMOVE_EVENT_RECEIVED } from '../store/action-types';
import CommentBubble from '../components/comment-bubble.vue';

const SCROLL_DELAY = 10;

export default {
  name: 'Chat',
  components: {
    'comment-bubble': CommentBubble,
  },
  props: {
    player: Object,
    userImage: String,
    isAnonymous: {
      type: Boolean,
      default: () => true,
    },
  },
  data() {
    return {
      commentText: '',
    };
  },
  beforeMount() {
    this.$store.dispatch(INITIALIZE, this.player.raffleId);
  },
  mounted() {
    this.fetchComments();
    this.subscribeToChatEvents();
  },
  channels: {
    ChatChannel: {
      connected() {},
      rejected() {},
      received(event) {
        this.onCommentEventReceived(humps.camelizeKeys(event));
      },
      disconnected() {},
    },
  },
  computed: {
    profileImage() {
      return this.isAnonymous ? `/characters/${this.player.characterUri}.png` : this.userImage;
    },
    isBanned() {
      return this.player.bannedAt;
    },
    comments() {
      return this.$store.state.chat.comments;
    },
    roomId() {
      return this.$store.state.chat.raffleId;
    },
  },
  watch: {
    comments(newValue, oldValue) {
      if (this.scrollComments(newValue, oldValue)) {
        setTimeout(() => this.scrollToBottom(), SCROLL_DELAY);
      }
    },
  },
  methods: {
    fetchComments() {
      this.$store.dispatch(FETCH_COMMENTS);
    },
    subscribeToChatEvents() {
      this.$cable.subscribe(humps.decamelizeKeys({
        channel: 'ChatChannel',
        roomId: this.roomId,
      }));
    },
    sendComment() {
      if (this.commentText) {
        this.$cable.perform({
          channel: 'ChatChannel',
          action: 'speak',
          data: humps.decamelizeKeys({
            roomId: this.roomId,
            playerId: this.player.id,
            anonymous: this.isAnonymous,
            text: this.commentText,
          }),
        });
        this.clearText();
      }
    },
    onCommentEventReceived(event) {
      if (!this.isValidRoom(event)) return;
      if (event.type === 'create') {
        this.$store.dispatch(COMMENT_RECEIVED, event.commentId);
      } else if (event.type === 'remove') {
        this.$store.dispatch(REMOVE_EVENT_RECEIVED, event.commentId);
      }
    },
    clearText() {
      this.commentText = '';
    },
    isValidRoom(event) {
      return event.roomId === this.roomId;
    },
    scrollComments(newValue, oldValue) {
      return Object.keys(newValue).length > Object.keys(oldValue).length;
    },
    scrollToBottom() {
      const commentsListElem = this.$refs.commentsList;
      commentsListElem.scrollTop = commentsListElem.scrollHeight;
    },
  },
};
</script>
