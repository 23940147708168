<template>
    <div class="cookie-consent" v-if="showConsentMessage">
        <div class="information-display">
          <p class="information-display__paragraph information-display__paragraph--first">
            {{ $t('cookies.informationMessage') }}
            <span class="information-display--hidden-mobile">
              {{ $t('cookies.moreInformationMessage') }}
              <a :href="cookiesInformationPath"> {{ $t('cookies.clickHere') }}. </a>
            </span>
          </p>
          <p class="information-display__paragraph information-display--hidden-desktop">
            {{ $t('cookies.moreInformationMessage') }}
            <a :href="cookiesInformationPath"> {{ $t('cookies.clickHere') }}. </a>
          </p>
        </div>
        <div class="button-content">
          <button class="btn btn-denied" @click="deniedCookies" > {{ $t('cookies.reyectButton') }} </button>
          <button class="btn btn-accept" @click="acceptCookies" > {{ $t('cookies.acceptButton') }} </button>
        </div>
    </div>
</template>
<script>
import baseCookieBehaviour from '../mixins/base-cookie-behaviour';

export default {
  name: 'CookieConsent',
  mixins: [baseCookieBehaviour],
  data() {
    return {
      cookiesInformationPath: '/cookies',
      showConsentMessage: true,
    };
  },
  beforeMount() {
    if (this.$cookies.get(this.cookieConsentedName) === 'true') {
      this.showConsentMessage = false;
    }
  },
  methods: {
    acceptCookies() {
      this.$cookies.keys().forEach(cookie => {
        if (this.consentCookies.includes(cookie)) {
          this.changeValue(cookie, true);
        }
      });
      this.closeConsentMessage();
    },
    deniedCookies() {
      this.changeValue(this.cookieConsentedName, true);
      this.changeValue(this.amigoSecretoCookieConsentName, false);
      this.deleteOtherCookies();
      this.closeConsentMessage();
    },
    closeConsentMessage() {
      this.showConsentMessage = false;
    },
  },
};
</script>
