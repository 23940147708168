export default {
  desktop: {
    chat: {
      comment: 'Kommentar!',
      commentsCall: 'Nur Teilnehmer dieses Auslosung können hier chatten.',
      commentsTitle: 'Anonymer Chat!',
      insideComment: 'Schreib hier, niemand wird wissen, wer du bist...',
    },
    raffle: {
      chatBanned: 'Du kannst den Chat nicht verwenden, da du vom Veranstalter der Auslosung blockiert wurdest.',
    },
  },
  modals: {
    default: {
      okBtn: 'Ok',
      cancelBtn: 'Abbrechen',
    },
    deleteComment: {
      title: 'Kommentar löschen',
      message: 'Möchtest du den Kommentar wirklich löschen? Diese Aktion kann nicht rückgängig gemacht werden...',
      okBtn: 'Löschen',
      cancelBtn: 'Abbrechen',
    },
  },
  payments: {
    checkout: {
      pay: 'Zahlen',
      discount: 'und abziehen',
      discountExplain: 'Wir fördern Lightning Network-Zahlungen.',
      discountPost: 'Lies in unserem Blog warum.',
      postURL: 'https://blog.platan.us/por-qué-te-dejamos-elegir-el-precio-usando-lightning-f05ba70c2bff',
    },
  },
  cookies: {
    informationMessage: 'Diese Webseite verwendet Cookies. Diese Cookies werden verwendet, um Informationen über dein Surfen zu sammeln, deine Erfahrung auf der Webseite zu verbessern und uns vor böswilligen Benutzern zu schützen.',
    moreInformationMessage: 'Für weitere Informationen klicke',
    clickHere: 'Hier',
    acceptButton: 'Akzeptieren',
    reyectButton: 'Ablehnen',
    informationView: {
      title: 'Cookies',
      firstPart: {
        title: 'Was sind Cookies?',
        firstParragraph: {
          mainContent: 'Cookies sind kleine Textelemente, die Webseiten im Browser installieren. Cookies helfen der Webseite zu funktionieren. Die meisten von ihnen werden beim Beenden des Browsers gelöscht, z. B. Sitzungscookies.',
          firstItem: 'Die <strong> Erstanbieter-Cookies </strong> werden von der von dir besuchten Webseite generiert. Diese Cookies können nur von dieser Seite gelesen werden.',
          secondItem: '<strong> Cookies von Drittanbietern </strong> werden von externen Diensten verwendet, die eine Webseite möglicherweise verwendet.',
          thirdItem: '<strong> dauerhafte Cookies </strong> werden beim Verlassen des Browsers nicht automatisch gelöscht.',
        },
        secondParragraph: 'Jedes Mal, wenn du unsere Webseite betrittst, werden einige Mindest-Cookies erstellt, die wir für das ordnungsgemäße Funktionieren unserer Dienste benötigen. Zum Beispiel ein Sitzungscookie und ein Cookie, um uns vor böswilligen Benutzern zu schützen.',
      },
      secondPart: {
        title: 'Wie verwenden wir Cookies?',
        firstParragraph: 'Unsere Webseite verwendet <strong> Erst- und Drittanbieter-Cookies </strong>.',
        secondParragraph: {
          mainContent: 'Die von uns verwendeten <strong> Erstanbieter-Cookies </strong> dienen zum Speichern deiner Cookie- und Sitzungseinstellungen.',
          firstItem: 'Um deine Präferenz und deine Zustimmung zur Verwendung von Cookies zu speichern, verwenden wir zwei Cookies, eines für deine Zustimmung und das andere, um herauszufinden, ob du auf die Zustimmung geantwortet hast. Diese Cookies haben eine Laufzeit von einem Jahr. Nach dieser Zeit löscht dein Browser sie.',
          secondItem: 'Um deine Sitzung aufrechtzuerhalten, verwenden wir ein Sitzungscookie, das in deinem Browser erstellt und beim Beenden des Browsers gelöscht wird. Dieses Cookie ist für den Betrieb der Webseite erforderlich.',
        },
      },
      thirdPart: {
        title: 'Cookies von Drittanbietern',
        firstParragraph: 'Um das Benutzerverhalten besser zu verstehen und die Webseite besser erleben zu können, verwenden wir <strong> Cookies von Drittanbietern </strong>, mit denen wir Benutzeraktionen analysieren können. Diese Cookies werden von Dritten erstellt, aber ausschließlich von uns verwaltet.',
        secondParragraph: {
          mainContent: 'Wir verwenden {wikipediaLink}, das gesammelte Informationen über den Verkehr auf der Webseite liefert. Damit dies funktioniert, musst du einige Cookies in deinem Browser erstellen. Für weitere Informationen zu Google Analytics-Cookies gib {gaLink} ein.',
          ga: 'Google Analytics',
        },
      },
      fourthPart: {
        title: 'Zustimmung ändern',
        firstParragraph: 'Wenn du nicht mehr möchtest, dass wir Cookies verwenden, klicke {clickHereLink}.',
        secondParragraph: 'Wenn du Cookies aktivieren möchtest, um unsere Funktionsweise zu verbessern, klicke {clickHereLink}.',
      },
    },
  },
};
