export default {
  data() {
    return {
      cookieConsentedName: 'cookieConsented',
      amigoSecretoCookieConsentName: 'amigo_secreto_cookie_consent',
    };
  },
  created() {
    this.consentCookies.forEach(cookie => {
      if (!this.$cookies.isKey(cookie)) {
        this.createCookie(cookie, false);
      }
    });
    if (this.$i18n.locale !== 'fr') {
      this.consentCookies.forEach(cookie => {
        this.changeValue(cookie, true);
      });
    }
  },
  beforeMount() {
    if (this.$cookies.get(this.cookieConsentedName) === 'true') {
      if (this.$cookies.get(this.amigoSecretoCookieConsentName) === 'false') {
        this.deleteOtherCookies();
      }
    }
  },
  computed: {
    consentCookies() {
      return [this.cookieConsentedName, this.amigoSecretoCookieConsentName];
    },
  },
  methods: {
    changeValue(cookieKeyName, value) {
      this.deleteCookie(cookieKeyName);
      this.createCookie(cookieKeyName, value);
    },
    deleteCookie(cookieKeyName) {
      this.$cookies.remove(cookieKeyName);
    },
    createCookie(cookieKeyName, value) {
      this.$cookies.set(cookieKeyName, value);
    },
    deleteOtherCookies() {
      this.$cookies.keys().forEach(cookie => {
        if (!this.consentCookies.includes(cookie)) {
          this.deleteCookie(cookie);
        }
      });
    },
  },
};
