import Vue from 'vue/dist/vue.esm';
import Vuex from 'vuex';

import chat from './modules/chat';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    chat,
  },
  strict: true,
});
