<template>
  <div class="bubble" v-bind:class="comment.owned ? 'own' : 'other'">
    <img :src="profileImage" />
    <div v-if="comment.owned" class="option" @click="openModal">
      <i class="fa fa-trash"></i>
    </div>
    <div class="bubble-text">
      {{ comment.text }}
      <div class="bubble-meta">
        {{ playerName }} <span class="bubble-meta-time"><i class="fa fa-clock-o"></i> {{ comment.createdAt }}</span>
      </div>
    </div>
    <base-modal
      ref="deleteCommentModal"
      :title="$t('modals.deleteComment.title')"
      :body="$t('modals.deleteComment.message')"
      :okBtn="$t('modals.deleteComment.okBtn')"
      :cancelBtn="$t('modals.deleteComment.cancelBtn')"
      :name="`modal-delete-${comment.id}`"
      @ok-clicked="deleteComment"
    >
    </base-modal>
  </div>
</template>

<script>

import { DELETE_COMMENT } from '../store/action-types';

import BaseModal from '../components/base/base-modal.vue';

export default {
  name: 'CommentBubble',
  components: {
    'base-modal': BaseModal,
  },
  props: {
    comment: Object,
  },
  computed: {
    profileImage() {
      return this.comment.anonymous ? this.comment.character.image : this.comment.player.user.picture;
    },
    playerName() {
      return this.comment.anonymous ? this.comment.character.name : this.comment.player.name;
    },
  },
  methods: {
    deleteComment() {
      this.$store.dispatch(DELETE_COMMENT, this.comment.id);
    },
    openModal() {
      this.$refs.deleteCommentModal.openModal();
    },
  },
};
</script>
