<template>
    <div>
        <div>
            <h1> {{ $t('cookies.informationView.title') }} </h1>
        </div>
        <div>
            <h2> {{ $t('cookies.informationView.firstPart.title') }} </h2>
            <p>
                {{ $t('cookies.informationView.firstPart.firstParragraph.mainContent') }}
                <ul>
                    <li v-html="$t('cookies.informationView.firstPart.firstParragraph.firstItem')"></li>
                    <li v-html="$t('cookies.informationView.firstPart.firstParragraph.secondItem')"></li>
                    <li v-html="$t('cookies.informationView.firstPart.firstParragraph.thirdItem')"></li>
                </ul>
            </p>
            <p>
                {{ $t('cookies.informationView.firstPart.secondParragraph') }}
            </p>
        </div>
        <div>
            <h2> {{ $t('cookies.informationView.secondPart.title') }} </h2>
            <p v-html="$t('cookies.informationView.secondPart.firstParragraph')"></p>
            <p v-html="$t('cookies.informationView.secondPart.secondParragraph.mainContent')"></p>
            <ul>
                <li>{{ $t('cookies.informationView.secondPart.secondParragraph.firstItem') }}</li>
                <li>{{ $t('cookies.informationView.secondPart.secondParragraph.secondItem') }}</li>
            </ul>
        </div>
        <div>
            <h2> {{ $t('cookies.informationView.thirdPart.title') }} </h2>
            <p v-html="$t('cookies.informationView.thirdPart.firstParragraph')"></p>
            <i18n path="cookies.informationView.thirdPart.secondParragraph.mainContent" tag="p">
                <template v-slot:wikipediaLink>
                    <a :href="wikipediGAPath" target="_blank"> {{ $t('cookies.informationView.thirdPart.secondParragraph.ga') }} </a>
                </template>
                <template v-slot:gaLink>
                    <a :href="cookiesGAPath" target="_blank"> {{ $t('cookies.clickHere') }} </a>
                </template>
            </i18n>
        </div>
        <div>
            <h2> {{ $t('cookies.informationView.fourthPart.title') }} </h2>
            <p v-if="changeConsent">
                <i18n path="cookies.informationView.fourthPart.firstParragraph" tag="p">
                    <template v-slot:clickHereLink>
                        <a @click="modifyConsent(false)" class="display-clickable"> {{ $t('cookies.clickHere') }} </a>
                    </template>
                </i18n>
            </p>
            <p v-else>
                <i18n path="cookies.informationView.fourthPart.secondParragraph" tag="p">
                    <template v-slot:clickHereLink>
                        <a @click="modifyConsent(true)" class="display-clickable" > {{ $t('cookies.clickHere') }} </a>
                    </template>
                </i18n>
            </p>
        </div>
    </div>
</template>
<script>
import baseCookieBehaviour from '../mixins/base-cookie-behaviour';

export default {
  name: 'CookiesInformation',
  mixins: [baseCookieBehaviour],
  data() {
    return {
      changeConsent: true,
      wikipediGAPath: 'https://wikipedia.org/wiki/Google_Analytics',
      cookiesGAPath: 'https://developers.google.com/analytics/resources/concepts/gaConceptsCookies',
    };
  },
  beforeMount() {
    if (this.$cookies.get(this.amigoSecretoCookieConsentName) === 'false') {
      this.changeConsent = false;
    }
  },
  methods: {
    modifyConsent(value) {
      this.changeValue(this.amigoSecretoCookieConsentName, value);
      this.changeValue(this.cookieConsentedName, true);
      this.changeConsent = !this.changeConsent;
    },
  },
};

</script>
