import enLocale from './en';
import esLocale from './es';
import frLocale from './fr';
import prLocale from './pr';
import deLocale from './de';

const messages = {
  en: enLocale,
  es: esLocale,
  fr: frLocale,
  pt: prLocale,
  de: deLocale,
};

export default { messages };
