import api from './api';
import objectToQueryString from './utils';

export default {
  getComments(raffleId, params) {
    const args = params || {};
    const path = `/api/raffles/${raffleId}/comments?${objectToQueryString(args)}`;
    return api({
      method: 'get',
      url: path,
    });
  },

  deleteComment(commentId) {
    const path = `/api/comments/${commentId}`;

    return api({
      method: 'delete',
      url: path,
    });
  },
};
