<template>
  <div class="gateways__option disable-select">
    <div
      class="gateways__image-container"
      @click="submit"
    >
      <img :src="paymentImage">
    </div>
    <div class="gateways__title">
      {{ this.gatewayName }}
    </div>
  </div>
</template>
<script>

export default {
  name: 'Gateway',
  props: {
    gatewayName: {
      type: String,
      default: '',
    },
    paymentMethod: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
    };
  },
  computed: {
    paymentImage() {
      return require(`assets/images/payments/${this.paymentMethod}.png`);
    },
  },
  methods: {
    createMethodInput() {
      const input = document.createElement('input');
      input.type = 'hidden';
      input.name = 'payment_method';
      input.value = this.paymentMethod;

      return input;
    },

    submit() {
      const form = document.getElementById('form-checkout');
      const input = this.createMethodInput();
      form.appendChild(input);
      form.submit();
    },
  },
};
</script>
