export default {
  desktop: {
    chat: {
      comment: 'Commenter!',
      commentsCall: 'Seuls les membres de ce tirage au sort peuvent chatter ici.',
      commentsTitle: 'Chat anonyme!',
      insideComment: 'Ecris ici, personne ne saura qui tu es...',
    },
    raffle: {
      chatBanned: "Tu ne peux pas utiliser le chat car tu as été bloqué par l'administrateur du tirage au sort.",
    },
  },
  modals: {
    default: {
      okBtn: 'Effacer',
      cancelBtn: 'Annuler',
    },
    deleteComment: {
      title: 'Effacer ce commentaire',
      message: 'Es-tu vraiment sûr de vouloir effacer ce commentaire? Cette action ne peut être annulée...',
      okBtn: 'Effacer',
      cancelBtn: 'Annuler',
    },
  },
  payments: {
    checkout: {
      pay: '',
      discount: 'et remise',
      discountExplain: 'Nous faisons la promotion des paiements Lightning Network.',
      discountPost: 'Lire pourquoi dans notre blog.',
      postURL: 'https://blog.platan.us/por-qué-te-dejamos-elegir-el-precio-usando-lightning-f05ba70c2bff',
    },
  },
  cookies: {
    informationMessage: 'Ce site Web stocke des cookies sur votre ordinateur. Ces cookies sont utilisés pour collecter des informations sur vous, pour améliorer votre expérience sur le site Web et pour nous protéger contre les utilisateurs malveillants.',
    moreInformationMessage: "Pour plus d'informations, ",
    clickHere: 'cliquez ici',
    acceptButton: "J'accepte",
    reyectButton: 'Rejeter',
    informationView: {
      title: 'Cookies',
      firstPart: {
        title: 'Que sont les cookies?',
        firstParragraph: {
          mainContent: 'Les cookies sont un petit fichier texte que les sites Web stockent sur votre navigateur. Les cookies aident à fournir un meilleur service. La plupart des cookies sont supprimés lorsque vous fermez le navigateur comme les cookies de session.',
          firstItem: 'Les <strong>cookies internes</strong> sont des cookies définis par le site Web que vous visitez. Seul ce site Web peut les lire.',
          secondItem: 'Les <strong>cookies tiers</strong> sont des cookies utilisés par des services externes et pourraient être utilisés par un site Web.',
          thirdItem: 'Les <strong>cookies persistants</strong> sont des cookies enregistrés sur votre ordinateur et ne sont pas supprimés automatiquement lorsque vous fermez le navigateur.',
        },
        secondParragraph: 'Chaque fois que vous visitez notre site Web, nous installons des cookies pour que notre site Web fonctionne correctement. Par exemple, un cookie de session et un cookie pour empêcher les actes malveillants.',
      },
      secondPart: {
        title: 'Comment utilisons-nous les cookies?',
        firstParragraph: 'Notre site Web utilise des <strong>cookies internes et tiers</strong>.',
        secondParragraph: {
          mainContent: 'Nous utilisons des <strong>cookies internes</strong> pour votre session et vos préférences en matière de cookies.',
          firstItem: "Pour votre préférence en matière de cookies et votre consentement, nous utilisons deux cookies, l'un pour votre préférence actuelle en matière de cookies et l'autre pour enregistrer votre préférence.",
          secondItem: 'Pour maintenir votre session actuelle, nous utilisons un cookie de session qui sera détruit lorsque vous fermerez le navigateur. Ce cookie est nécessaire au bon fonctionnement du site Web.',
        },
      },
      thirdPart: {
        title: 'Cookies tiers',
        firstParragraph: "Pour une meilleure compréhension du comportement des utilisateurs et pour être en mesure d'améliorer l'expérience utilisateur, nous utilisons des <strong>cookies tiers.</strong> Ces cookies sont créés par des services externes mais utilisés uniquement par nous.",
        secondParragraph: {
          mainContent: "Nous utilisons {wikipediaLink}, un outil qui nous aide à mesurer la façon dont les utilisateurs interagissent avec le contenu de notre site Web. Pour travailler, Google Analytics crée quelques cookies dans votre navigateur. Pour plus d'informations, {gaLink}.",
          ga: 'Google Analytics',
        },
      },
      fourthPart: {
        title: 'Modifier le consentement',
        firstParragraph: 'Si vous ne souhaitez plus que nous utilisions des cookies, cliquez sur {clickHereLink}.',
        secondParragraph: 'Si vous souhaitez activer les cookies, afin que nous puissions améliorer notre service, cliquez sur {clickHereLink}',
      },
    },
  },
};
