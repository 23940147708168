export default {
  desktop: {
    chat: {
      comment: 'Comentar!',
      commentsCall: 'Somente membros deste sorteio podem conversar aqui.',
      commentsTitle: 'Chat anônimo!',
      insideComment: 'Escreva aqui, ninguém saberá quem é...',
    },
    raffle: {
      chatBanned: 'Você não pode utilizar o chat porque foi bloqueado pelo administrador do sorteio.',
    },
  },
  modals: {
    default: {
      okBtn: 'Aceitar',
      cancelBtn: 'Cancelar',
    },
    deleteComment: {
      title: 'Remover comentário',
      message: 'Você deseja realmente remover o comentário? Essa ação não pode ser desfeita...',
      okBtn: 'Apagar',
      cancelBtn: 'Cancelar',
    },
  },
  payments: {
    checkout: {
      pay: 'Pague',
      discount: 'e desconto',
      discountExplain: 'Estamos promovendo pagamentos da Rede Lightning.',
      discountPost: 'Leia por que no nosso blog.',
      postURL: 'https://blog.platan.us/por-qué-te-dejamos-elegir-el-precio-usando-lightning-f05ba70c2bff',
    },
  },
  cookies: {
    informationMessage: 'Este site armazena cookies no seu computador. Esses cookies são usados ​​para coletar informações sobre sua navegação, melhorar sua experiência no site e nos proteger de usuários mal-intencionados.',
    moreInformationMessage: 'Para mais informações, clique ',
    clickHere: 'aqui',
    acceptButton: 'Aceitar',
    reyectButton: 'Rejeitar',
    informationView: {
      title: 'Cookies',
      firstPart: {
        title: 'O que são cookies?',
        firstParragraph: {
          mainContent: 'Cookies são um pequeno arquivo de texto que os sites armazenam no seu navegador. Os cookies ajudam a fornecer um serviço melhor. A maioria dos cookies é limitada quando você fecha o navegador como cookies de sessão.',
          firstItem: '<strong>Cookies primários</strong> são cookies definidos pelo site que você está visitando. Somente esse site pode lê-los.',
          secondItem: '<strong>Cookies de terceiros</strong> são cookies usados ​​por serviços externos, que podem usar o site.',
          thirdItem: '<strong>Cookies persistentes</strong> são cookies salvos no seu computador e não são excluídos automaticamente quando você fecha o navegador.',
        },
        secondParragraph: 'Cada vez que você visita nosso site, definiremos alguns cookies para que nosso site funcione corretamente. Por exemplo, um cookie de sessão e um cookie para impedir atos maliciosos.',
      },
      secondPart: {
        title: 'Como usamos cookies?',
        firstParragraph: 'Nosso site usa <strong>cookies primários e de terceiros</strong>.',
        secondParragraph: {
          mainContent: 'Usamos <strong> cookies primários </strong> para sua sessão e sua preferência de cookies.',
          firstItem: 'Para sua preferência e consentimento, usamos dois cookies, um para sua preferência real e outro para armazenar sua preferência.',
          secondItem: 'Para manter sua sessão atual, usamos uma sessão de cookie que será destruída quando você fechar o navegador. Este cookie é necessário para o site funcionar corretamente.',
        },
      },
      thirdPart: {
        title: 'Cookies de terceiros',
        firstParragraph: 'Para uma melhor compreensão do comportamento do usuário e para melhorar a experiência do usuário, usamos <strong> cookies de terceiros </strong>. Esses cookies são criados por serviços externos, mas usados ​​apenas por nós.',
        secondParragraph: {
          mainContent: 'Usamos o {wikipediaLink}, uma ferramenta que nos ajuda a avaliar como os usuários interagem com o conteúdo do site. Para trabalhar, o Google Analytics cria alguns cookies no seu navegador. Para mais informações, clique em {gaLink}.',
          ga: 'Google Analytics',
        },
      },
      fourthPart: {
        title: 'Alterar consentimento',
        firstParragraph: 'Se você não quiser mais usar cookies, clique em {clickHereLink}.',
        secondParragraph: 'Se você deseja ativar os cookies, para melhorar nosso serviço, clique em {clickHereLink}',
      },
    },
  },
};
