export const INITIALIZE = 'INITIALIZE';

export const FETCH_COMMENTS = 'FETCH_COMMENTS';
export const FETCH_COMMENT = 'FETCH_COMMENT';
export const FETCH_COMMENTS_FAILURE = 'FETCH_COMMENTS_FAILURE';
export const FETCH_COMMENTS_SUCCESSFUL = 'FETCH_COMMENTS_SUCCESSFUL';
export const FETCH_COMMENT_SUCCESSFUL = 'FETCH_COMMENT_SUCCESSFUL';

export const COMMENT_RECEIVED = 'COMMENT_RECEIVED';
export const REMOVE_EVENT_RECEIVED = 'REMOVE_EVENT_RECEIVED';

export const DELETE_COMMENT = 'DELETE_COMMENT';
export const DELETE_COMMENT_FAILURE = 'DELETE_COMMENT_FAILURE';
export const DELETE_COMMENT_SUCCESSFUL = 'DELETE_COMMENT_SUCCESSFUL';
