<template>
  <div class="checkout">
    <div class="checkout__title">
      <div class="checkout__price">
        <h1 class="checkout__title-text">
          {{ $t('payments.checkout.pay') }}
        </h1>
        <div class="checkout__value-container">
          <h1 class="checkout__value">
            {{ calculatePrice() }} USD
          </h1>
        </div>
      </div>

      <div class="checkout__discount">
        <h1 class="checkout__title-text mobile-shifted">
          {{ $t('payments.checkout.discount') }}
        </h1>
        <div class="checkout__value-container">
          <button
            class="checkout__discount-button"
            @click.prevent="incrementValue()"
          >
            <h1 class="checkout__discount-button-text">
              +
            </h1>
          </button>
          <h1 class="checkout__discount-text">
            {{ value }}%
          </h1>
          <button
            class="checkout__discount-button"
            @click.prevent="decrementValue()"
          >
            <h1 class="checkout__discount-button-text">
              -
            </h1>
          </button>
        </div>

        <input
          type="hidden"
          name="discount_factor"
          :value="value / 100"
        >
      </div>
    </div>

    <transition-group
      name="hide"
      tag="div"
      class="gateways"
    >
      <gateway
        v-for="gateway in filteredGateways"
        :key="gateway.payment_method"
        :gateway-name="gateway.payment_name"
        :payment-method="gateway.payment_method"
      />
    </transition-group>

    <div class="checkout__information">
      <span>{{ $t('payments.checkout.discountExplain') }}</span> <a :href="postURL"> {{ $t('payments.checkout.discountPost') }}</a>
    </div>
  </div>
</template>

<script>

import Gateway from '../components/gateway.vue';

export default {
  name: 'Checkout',
  components: {
    'gateway': Gateway,
  },
  props: {
    gateways: {
      type: Array,
      default: () => [],
    },
    price: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      value: 0,
    };
  },
  computed: {
    filteredGateways() {
      if (this.value !== 0) {
        return this.gateways.filter(gateway => gateway.payment_method === 'lightning');
      }

      return this.gateways;
    },

    postURL() {
      return this.$i18n.t('payments.checkout.postURL');
    },
  },
  methods: {
    calculatePrice() {
      const DECIMALS = 2;
      const HUNDRED = 100;
      const DISCOUNT_FACTOR = (1 - this.value / HUNDRED);

      return (DISCOUNT_FACTOR * this.price).toFixed(DECIMALS);
    },

    incrementValue() {
      const UPPERLIMIT = 99;
      if (this.value === UPPERLIMIT) return;
      this.value += 1;
    },

    decrementValue() {
      const LOWERLIMIT = -99;
      if (this.value === LOWERLIMIT) return;
      this.value -= 1;
    },
  },
};

</script>
