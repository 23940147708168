export default {
  desktop: {
    chat: {
      comment: 'Comentar!',
      commentsCall: 'Sólo los miembros de este sorteo pueden conversar aquí.',
      commentsTitle: 'Chat anónimo!',
      insideComment: 'Escribe aquí, nadie sabrá quién eres...',
    },
    raffle: {
      chatBanned: 'No puedes utilizar el chat porque has sido bloqueado por el administrador del sorteo.',
    },
  },
  modals: {
    default: {
      okBtn: 'Ok',
      cancelBtn: 'Cancelar',
    },
    deleteComment: {
      title: 'Borrar Comentario',
      message: '¿Realmente quieres borrar el comentario? Esta acción no puede deshacerse...',
      okBtn: 'Delete',
      cancelBtn: 'Cancelar',
    },
  },
  payments: {
    checkout: {
      pay: 'Pagar',
      discount: 'y descontar',
      discountExplain: 'Estamos promoviendo los pagos de Lightning Network.',
      discountPost: 'Lee por qué en nuestro blog.',
      postURL: 'https://blog.platan.us/por-qué-te-dejamos-elegir-el-precio-usando-lightning-f05ba70c2bff',
    },
  },
  cookies: {
    informationMessage: 'Este sitio web utiliza cookies. Estas cookies son utilizadas para recolectar información de tu navegación, mejorar tu experiencia en el sitio y protegernos de usuarios maliciosos.',
    moreInformationMessage: 'Para más información ingresa',
    clickHere: 'aquí',
    acceptButton: 'Aceptar',
    reyectButton: 'Rechazar',
    informationView: {
      title: 'Cookies',
      firstPart: {
        title: '¿Qué son las cookies?',
        firstParragraph: {
          mainContent: 'Las cookies son pequeños elementos de texto que los sitios web instalan en el navegador. Las cookies ayudan al funcionamiento del sitio web. La mayoría se eliminan al salir del navegador, como por ejemplos las cookies de sesión.',
          firstItem: 'Las <strong> cookies de origen </strong> son aquellas generadas por el sitio web que estás visitando. Estas cookies solo las puede leer ese sitio.',
          secondItem: 'Las <strong> cookies de terceros </strong> son aquellas utilizadas por servicios externos que pueda utilizar un sitio web.',
          thirdItem: 'Las <strong> cookies persistentes </strong> son aquellas que no se eliminan automáticamente al salir del navegador.',
        },
        secondParragraph: 'Cada vez que ingreses a nuestro sitio web se crearán alguna cookies mínimas que requerimos para el correcto funcionamiento de nuestros servicios. Por ejemplo, una cookie de sesión y una cookie para protegernos de usuarios maliciosos.',
      },
      secondPart: {
        title: '¿Cómo utilizamos las cookies?',
        firstParragraph: 'Nuestro sitio web ocupa <strong>cookies de origen y de terceros</strong>.',
        secondParragraph: {
          mainContent: 'Las <strong> cookies de origen </strong> que ocupamos son para almacenar tu configuración de cookies y de sessión.',
          firstItem: 'Para almacenar tu preferencia y consentimiento del uso cookies ocupamos dos cookies, una para tu consentimiento y otra para saber si respondiste el consentimiento. Estas cookies tienen una duración de un año, por lo que luego de este tiempo las eliminará tu navegador.',
          secondItem: 'Para mantener tu sesión ocupamos una cookie de sesión que será creada en tu navegador y se elimina al salir del navegador. Esta cookie es necesaria para el funcionamiento del sitio.',
        },
      },
      thirdPart: {
        title: 'Cookies de terceros',
        firstParragraph: 'Para tener un mejor conocimiento del comportamiento de los usuarios y poder mejor la experiencia en el sitio web utilizamos <strong> cookies de terceros </strong> que nos permiten analizar las acciones de los usuarios. Estas cookies son creadas por terceros pero manejadas exclusivamente por nosotros.',
        secondParragraph: {
          mainContent: 'Utilizamos {wikipediaLink}, el cual entrega información agrupada del tráfico que recibe el sitio web. Para que esta funcione debe crear algunas cookies en tu navegador. Para mayor información sobre las cookies de Google Analytics ingrese {gaLink}.',
          ga: 'Google Analytics',
        },
      },
      fourthPart: {
        title: 'Cambiar consentimiento',
        firstParragraph: 'Si ya no quieres que utilicemos cookies presiona {clickHereLink}.',
        secondParragraph: 'Si quieres activar las cookies para mejorar nuestro funcionamiento presiona {clickHereLink}.',
      },
    },
  },
};
