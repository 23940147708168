<template lang="html">
  <modal :name="name" height="auto" :adaptive="true">
    <div class="wizard-modal">
      <div class="modal-header">
        <h3 v-if="title" class="modal-title">
          {{ title }}
        </h3>
      </div>
      <div v-if="body" class="modal-body wizard-modal-body">
        {{ body }}
      </div>
      <div class="modal-footer">
        <button class="raffle-modal-delete-cancel-button btn btn-info" @click="closeModal">
          {{ cancelBtn ? cancelBtn : $t('modals.default.cancelBtn') }}
        </button>
        <button class="raffle-modal-delete-confirm-button btn btn-danger" @click="okClicked">
          {{ okBtn ? okBtn : $t('modals.default.okBtn') }} <i class="fa fa-exclamation"></i>
        </button>
      </div>
    </div>
  </modal>
</template>

<script>

export default {
  name: 'BaseModal',
  props: {
    title: String,
    body: String,
    cancelBtn: String,
    okBtn: String,
    name: {
      type: String,
      default: 'base-modal',
    },
  },
  methods: {
    okClicked(event) {
      this.closeModal();
      this.$emit('ok-clicked', event);
    },
    openModal() {
      this.$modal.show(this.name);
    },
    closeModal() {
      this.$modal.hide(this.name);
    },
  },
};
</script>

