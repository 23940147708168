import {
  SET_RAFFLE,
  LOADING_START,
  LOADING_END,
  SET_COMMENTS,
  REMOVE_COMMENT,
  ADD_COMMENTS,
} from '../../mutation-types';

import {
  INITIALIZE,
  FETCH_COMMENTS,
  FETCH_COMMENT,
  FETCH_COMMENTS_FAILURE,
  FETCH_COMMENTS_SUCCESSFUL,
  FETCH_COMMENT_SUCCESSFUL,
  COMMENT_RECEIVED,
  REMOVE_EVENT_RECEIVED,
  DELETE_COMMENT,
  DELETE_COMMENT_SUCCESSFUL,
  DELETE_COMMENT_FAILURE,
} from '../../action-types';

import commentApi from '../../../api/comments';

export default {
  [INITIALIZE]({ commit }, raffleId) {
    commit(SET_RAFFLE, raffleId);
  },
  [FETCH_COMMENTS]({ state, commit, dispatch }) {
    commit(LOADING_START);
    commentApi.getComments(state.raffleId)
      .then(comments => dispatch(FETCH_COMMENTS_SUCCESSFUL, comments))
      .catch(err => dispatch(FETCH_COMMENTS_FAILURE, err));
  },
  [FETCH_COMMENT]({ state, commit, dispatch }, commentId) {
    commit(LOADING_START);
    commentApi.getComments(state.raffleId, { 'since_id': commentId - 1, order: 'asc', count: 1 })
      .then(comments => dispatch(FETCH_COMMENT_SUCCESSFUL, comments))
      .catch(err => dispatch(FETCH_COMMENTS_FAILURE, err));
  },
  [FETCH_COMMENTS_SUCCESSFUL]({ commit }, comments) {
    commit(SET_COMMENTS, comments);
    commit(LOADING_END);
  },
  [FETCH_COMMENT_SUCCESSFUL]({ commit }, comments) {
    commit(ADD_COMMENTS, comments);
    commit(LOADING_END);
  },
  [FETCH_COMMENTS_FAILURE]({ commit }) {
    commit(LOADING_END);
  },
  [COMMENT_RECEIVED]({ dispatch }, commentId) {
    dispatch(FETCH_COMMENT, commentId);
  },
  [REMOVE_EVENT_RECEIVED]({ commit }, commentId) {
    commit(REMOVE_COMMENT, commentId);
  },
  [DELETE_COMMENT]({ commit, dispatch }, commentId) {
    commit(LOADING_START);
    commentApi.deleteComment(commentId)
      .then(() => dispatch(DELETE_COMMENT_SUCCESSFUL))
      .catch(err => dispatch(DELETE_COMMENT_FAILURE, err));
  },
  [DELETE_COMMENT_SUCCESSFUL]({ commit }) {
    commit(LOADING_END);
  },
  [DELETE_COMMENT_FAILURE]({ commit }) {
    commit(LOADING_END);
  },
};
