import Vue from 'vue/dist/vue.esm';
import VueI18n from 'vue-i18n';
import VModal from 'vue-js-modal';
import ActionCableVue from 'actioncable-vue';
import { camelizeKeys } from 'humps';
import VueCookies from 'vue-cookies';

import Chat from '../components/chat.vue';
import Checkout from '../components/checkout.vue';
import CookieConsent from '../components/cookie-consent.vue';
import CookiesInformation from '../components/cookies-information.vue';

import Locales from '../locales/locales.js';
import store from '../store';

Vue.use(VueI18n);
Vue.use(VModal);
Vue.use(ActionCableVue, { connectionUrl: '/cable' });
Vue.use(VueCookies);
Vue.$cookies.config('1y', '', `.${location.hostname.replace(/^www\./i, '')}`);

Vue.filter('camelizeKeys', camelizeKeys);
Vue.component('chat', Chat);
Vue.component('checkout', Checkout);
Vue.component('cookie-consent', CookieConsent);
Vue.component('cookies-information', CookiesInformation);

let locale = '';
switch (window.location.hostname) {
case 'www.sorteoamigosecreto.com':
  locale = 'es';
  break;
case 'www.secretsantadrawing.com':
  locale = 'en';
  break;
case 'www.sorteioamigosecreto.com':
  locale = 'pt';
  break;
case 'www.perenoelsecret.fr':
  locale = 'fr';
  break;
case 'www.daswichteln.de':
  locale = 'de';
  break;
default:
  locale = 'es';
}

document.addEventListener('DOMContentLoaded', () => {
  let app;
  let cookieConsentApp;
  if (document.getElementById('app') !== null) {
    app = new Vue({
      el: '#app',
      i18n: new VueI18n({
        locale,
        messages: Locales.messages,
      }),
      store,
    });
  }

  if (document.getElementById('cookie-consent-div') !== null) {
    cookieConsentApp = new Vue({
      el: '#cookie-consent-div',
      i18n: new VueI18n({
        locale,
        messages: Locales.messages,
      }),
      store,
    });
  }

  return [app, cookieConsentApp];
});
