export default {
  desktop: {
    chat: {
      comment: 'Comment!',
      commentsCall: 'Only the members of this drawing can chat here.',
      commentsTitle: 'Anonymous chat!',
      insideComment: 'Write here, no one will know who you are...',
    },
    raffle: {
      chatBanned: 'You cannot use chat because it has been blocked by the drawing administrator.',
    },
  },
  modals: {
    default: {
      okBtn: 'Ok',
      cancelBtn: 'Cancel',
    },
    deleteComment: {
      title: 'Delete Comment',
      message: 'Do you really want to delete this comment? This action cannot be undone...',
      okBtn: 'Delete',
      cancelBtn: 'Cancel',
    },
  },
  payments: {
    checkout: {
      pay: 'Pay',
      discount: 'and discount',
      discountExplain: 'We are promoting Lightning Network payments.',
      discountPost: 'Read why in our blog.',
      postURL: 'https://blog.platan.us/por-qué-te-dejamos-elegir-el-precio-usando-lightning-f05ba70c2bff',
    },
  },
  cookies: {
    informationMessage: 'This website stores cookies on your computer. These cookies are used to collect information about you navegation, improve your experience in the website and protect us from malicious users.',
    moreInformationMessage: 'For more information click',
    clickHere: 'here',
    acceptButton: 'Accept',
    reyectButton: 'Reject',
    informationView: {
      title: 'Cookies',
      firstPart: {
        title: 'What are cookies?',
        firstParragraph: {
          mainContent: 'Cookies are a small text file that websites stores on your browser. Cookies help to provide a better service. Most cookies are eliminated when you close de browser like session cookies.',
          firstItem: '<strong>First party cookies</strong> are cookies set by de website you are visiting. Only that website can read them.',
          secondItem: '<strong>Third party cookies</strong> are cookies used by external services that could use the website.',
          thirdItem: '<strong>Persistent cookies</strong> are cookies saved on your computer and not deleted automatically when you close the browser.',
        },
        secondParragraph: 'Each time you visit our website we will set some cookies to make our website work correctly. For example, a session cookie and a cookie to prevent malicious acts.',
      },
      secondPart: {
        title: 'How do we use cookies?',
        firstParragraph: 'Our website uses <strong>first party and third party cookies</strong>.',
        secondParragraph: {
          mainContent: 'We use <strong>first party cookies</strong> for your session and your cookies preference.',
          firstItem: 'For your cookie preference and consent we use two cookies, one for your actual cookies preference and the other one to store your consent.',
          secondItem: 'To maintain your current session we use a cookie session that will be destroy when you close the browser. This cookie is necessgary for the website to run correctly.',
        },
      },
      thirdPart: {
        title: 'Third party cookies',
        firstParragraph: 'For a better undarstanding of our user behaviour and to be able to improve are user experience we use <strong>third party cookies</strong>. This cookies are created by external services but used only by us.',
        secondParragraph: {
          mainContent: 'We use {wikipediaLink}, a tools that help us measure how users interact with our website content. To work Google Analytics creates a few cookies in your browser. For more information click {gaLink}.',
          ga: 'Google Analytics',
        },
      },
      fourthPart: {
        title: 'Change consent',
        firstParragraph: "If you don't longer want us to use cookies click {clickHereLink}.",
        secondParragraph: 'If you want to activate cookies, so we can improve our service click {clickHereLink}.',
      },
    },
  },
};
