export const SET_RAFFLE = 'SET_RAFFLE';

export const LOADING_START = 'LOADING_START';
export const LOADING_END = 'LOADING_END';

export const SET_COMMENTS = 'SET_COMMENTS';

export const REMOVE_COMMENT = 'REMOVE_COMMENT';

export const ADD_COMMENTS = 'ADD_COMMENTS';
