import {
  SET_RAFFLE,
  LOADING_START,
  LOADING_END,
  SET_COMMENTS,
  REMOVE_COMMENT,
  ADD_COMMENTS,
} from '../../mutation-types';

export default {
  [SET_RAFFLE](state, raffleId) {
    state.raffleId = raffleId;
  },
  [LOADING_START](state) {
    state.fetching = true;
  },
  [LOADING_END](state) {
    state.fetching = false;
  },
  [SET_COMMENTS](state, comments) {
    state.comments = comments;
  },
  [REMOVE_COMMENT](state, commentId) {
    state.comments = [
      ...state.comments.filter(res => res.id !== commentId),
    ];
  },
  [ADD_COMMENTS](state, comments) {
    state.comments = state.comments.concat(comments);
  },
};
